import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var useCodesPermissions = function (user) {
    var hasPermissionListCode = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.CODE, MethodCode.LIST);
    var hasPermissionEditCode = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.CODE, MethodCode.EDIT);
    var hasPermissionViewCode = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.CODE, MethodCode.VIEW);
    var hasPermissionDeleteCode = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.CODE, MethodCode.DELETE);
    return {
        hasPermissionListCode: hasPermissionListCode,
        hasPermissionEditCode: hasPermissionEditCode,
        hasPermissionViewCode: hasPermissionViewCode,
        hasPermissionDeleteCode: hasPermissionDeleteCode,
    };
};
export default useCodesPermissions;
